<template>
  <div class="activity-container">
    <van-skeleton :row="15" :loading="loading">
      <div style="margin-top: 20px" v-html="activity"></div>
    </van-skeleton>
  </div>
</template>

<script>
import { getGroupActivityInfo } from "@/api/api";

// @ is an alias to /src
export default {
  name: "Activity",
  data() {
    return {
      loading: true,
      active: "0",
      activity: '',
      env: "",
    };
  },
  created() {
    let id = this.$route.query.id;
    if (!id) {
      this.$failToast("参数错误");
      return;
    }
    getGroupActivityInfo({
      id,
    }).then((res) => {
      console.log("活动结果===>", res);
      this.activity = res.data;
      this.loading = false;
    });
  },
  // 在使用到的页面 添加如下代码
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss">
.activity-container {
  font-size: 14px;
  padding: 20px;

  .share-box {
    margin-top: 30px;
    .share-to {
      height: 14px;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
    }

    .invite-way {
      width: 296px;
      height: 72px;
      // background: #fefefe;
      opacity: 0.8;
      border-radius: 8px;
      display: flex;
      justify-content: space-around;
      .invite-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 12px;
      }

      .wx-share {
        position: relative;
      }
    }
    .event-desc {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      font-size: 12px;
      font-weight: 500;
      color: #666666;
    }
  }

  .title {
    color: #333333;
    font-size: 17px;
    font-weight: bold;
  }

  .sub-title {
    color: #666666;
    font-size: 14px;
  }

  .house-container {
    display: flex;
    overflow-x: auto;
  }

  .house-container::-webkit-scrollbar {
    display: none;
  }

  .container {
    margin-bottom: 20px;
    .sub-title {
      margin-bottom: 5px;
    }
    .more {
      color: #666666;
      font-size: 12px;
    }

    .house-item {
      width: 240px;
      margin-right: 10px;
      color: #646060;
      .cover {
        width: 240px;
        height: 161px;
      }
      .name {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .tag-p {
        width: 100%;
        height: 30px;
        overflow: hidden !important;
      }
      .tag {
        // display: inline-block;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 1px;
        // min-width: 56px;
        height: 18px;
        text-align: center;
        color: #333;
        font-size: 11px;
        border-radius: 2px;
        margin: 5px 5px 2px 0px;
        // margin-right: 10px;
        padding: 2px;
        font-weight: 500;
        // overflow: hidden;
      }

      @media only screen and(max-width: 320px) {
        .tag:nth-child(3n + 0) {
          margin-right: 0px;
        }
      }
      @media only screen and(min-width: 400px) {
        .tag:nth-child(4n + 0) {
          margin-right: 0px;
        }
      }

      .facilities {
        font-size: 11px;
        color: #9e9d9d;
      }
      .icon {
        width: 12px;
        height: 9px;
      }

      .facilities-container {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
      }
      .price > span:nth-child(1) {
        color: #f67f58;
        font-size: 15px;
      }
      .price > span:nth-child(2) {
        color: #646060;
        font-size: 11px;
      }
    }
  }
}
</style>
